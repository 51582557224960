





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import api from '@/sdk/api/Api.ts';
import Cookie from 'js-cookie';
import Auth from '@/sdk/common/Auth';
// import login from '@/components/business/pc/header/InsLogin.vue';
// import shopcart from '@/components/business/pc/header/InsShoppingCart.vue';
// import search from '@/components/business/pc/header/InsSearch.vue';
import { Form as ElForm, Input, Message } from 'element-ui';
@Component({
  components: {
    login: () => import('@/components/business/pc/header/InsLogin.vue'),
    shopcart: () => import('@/components/business/pc/header/InsShoppingCart.vue'),
    search: () => import('@/components/business/pc/header/InsSearch.vue')
  }
})
export default class InsHeader extends Vue {
  @Prop() private msg!: string;
  codeId: string = '1';
   private key:string = '';
   private count:number = 0;
   private bigLogo:boolean = true;
   MemberName:string= this.$store.state.user;
   // private hhh: any = this.$t('test');
   To (n) {
     if (n.RedirectType !== undefined && n.RedirectType !== -1) return this.To({ Type: n.RedirectType, Value: { Id: n.RedirectValue }, Url: n.RedirectValue });
     return n.Type === 0 ? n.Url : n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/CMS/content/' + n.Value.Id : n.Type === 3 ? '/RegNPay/Form/' + n.Value.Id : (n.Type === 4 && n.Value.Id) ? '/product/cat/' + n.Value.Id : n.Type === 5 ? '/product/search/+?attrs=' + JSON.stringify([{ Id: parseInt(n.Value.Id), Vals: [] }]) + '&type=0' : '/';
   }
   changeCurrency () {
     this.$Api.member.setCurrency(this.codeId).then((result) => {
       this.$message({
         message: this.$t('changeCurSuccess') as string,
         type: 'success'
       });
       this.$store.dispatch('setCurrency', result.Currency.Id);
       this.$Storage.set('currency', result.Currency.Id);
       this.Reload();
     }).catch((error) => {
       console.log(error);
     });
   }
   // 获取会员信息
   getMemberInfo () {
     let _this = this;

     this.$Api.member.getAccount().then(
       function (data) {
         if (data.Logined) {
           _this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
           _this.$store.dispatch('doLogin');
         } else {
           _this.$store.dispatch('Logout');
         }
       },
       function (data) {
         Message({
           message: data,
           type: 'error'
         });
       }
     );
   }
   logout () {
     this.$Api.member.logout().then((result) => {
       if (result) this.$message('Message.SucceedInOperating');
       this.Reload();
       this.$store.dispatch('Logout');
       // storage.remove('access_token');
       Cookie.remove('access_token');
       Auth.GetToken();
       //  window.location.reload();
     });
   }
   lang = 'C';
   private changLange (lang) {
     this.$Api.member.setUILanguage(lang).then((result) => {
       this.$i18n.locale = lang;
       this.$Storage.set('locale', lang);
       Cookie.set('uLanguage', lang);
       //  window.location.reload();
       this.Reload();
     }).catch((error) => {
       console.log(error);
     });
   }
   get ShopCart () {
     return this.$store.state.shopCart;
   }
   searchKeyChange () {
     this.$store.dispatch('setSearchKey', this.key);
     if (this.key !== '') {
       this.$router.push({
         path: '/product/search',
         name: 'productSearch',
         params: {
           key: this.key
         }
       });
     } else {
       this.$router.push({
         path: '/product/search/+'
       });
     }
   }
   getMenu () {
     if (window['Menu']) {
       window['Menu'].then((result) => {
         this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
         this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
       });
       return;
     }
     this.$Api.promotion.getMenu().then((result) => {
       this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
       this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
     }).catch((error) => {
       console.log(error);
     });
   }
   get user () {
     return this.$store.state.user;
   }
   @Watch('ShopCart')
   onShopCartChange () {
     this.ShopCart.then((result) => {
       this.count = result.ShopCart.Qty;
     });
   }
   // private changLange (lang) {
   //   this.$i18n.locale = lang;
   //   // console.log(this.hhh, this.$t('test'),'test');
   //   localStorage.setItem('locale', lang);
   // }
   created () {
     //  this.codeId = String(this.$store.state.currency.Currency.Id);
     this.lang = this.$i18n.locale;
     let code = this.$Storage.get('currency');
     if (code) this.codeId = code;
     if (this.$route.name !== 'home') { this.bigLogo = false; }
     window.onscroll = () => {
       if (this.$route.name !== 'home') return;
       let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
       if (scrollTop >= 100) this.bigLogo = false;
       else this.bigLogo = true;
     };
   }
 @Watch('$route', { deep: true })
   onRouteChange () {
     if (this.$route.name !== 'home') { this.bigLogo = false; } else { this.bigLogo = true; }
   }
 mounted () {
   this.getMenu();
   this.getMemberInfo();
 }
}
